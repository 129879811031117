let locale = "ru-RU";

export let { format: intFormat } = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 0
});

export let { format: floatFormat } = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export let { format: currencyIntFormat } = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "RUB",
    currencyDisplay: "symbol",
    maximumFractionDigits: 0
});

export let { format: currencyFloatFormat } = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "RUB",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

let { format: nativePercentIntFormat } = new Intl.NumberFormat(locale, {
    style: "percent",
    maximumFractionDigits: 0
});

export let percentIntFormat = percent => nativePercentIntFormat(percent).replace(/\s/g, "");

let { format: nativePercentFloatFormat } =  new Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export let percentFloatFormat = percent => nativePercentFloatFormat(percent).replace(/\s/g, "");

export function durationFormat(duration) {
    if (duration === "") {
        return duration;
    }

    let hours = Math.trunc(duration / 3600).toString().padStart(2, "0");
    let minutes = Math.trunc(duration / 60 % 60).toString().padStart(2, "0");
    let seconds = Math.trunc(duration  % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
}

export function secondsToMinutes(seconds) {
    return Math.round(seconds / 60);
}

export function yesOrNo(value) {
    return value ? "Да" : "Нет";
}
