import Vue from "vue";
import axios from "axios";
import { daterangeKey, dateKey } from "@/helpers/daterange";

function toneInitialState() {
    return {
        reportId: null,
        modeId: "base",
        typeId: "distribution",
        viewId: "table",
        originId: "restaurants",
        origins: [
            { id: "restaurants", title: "Рестораны" },
            { id: "source", title: "Источники" },
            { id: "group", title: "Направления" }
        ],
        sortings: {
            items: {
                field: "tone",
                direction: "DESC"
            },
            reasons: {
                field: "total",
                direction: "DESC"
            }
        },
        compareDaterange: { start: null, end: null },
        data: {},
    };
}

export default {
    state: () => toneInitialState(),
    mutations: {
        setToneParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        setToneSorting(state, { table, field }) {
            if (state.sortings[table].field === field) {
                state.sortings[table].direction = state.sortings[table].direction === "ASC" ? "DESC" : "ASC";
            } else {
                state.sortings[table].field = field
            }
        },
        setDaterangeTones(state, dangers) {
            dangers.forEach(({ daterange, tone }) => Vue.set(state.data, daterangeKey(daterange), tone));
        },
        resetToneState(state) {
            Object.assign(state, toneInitialState());
        }
    },
    actions: {
        requestTones: ({ commit, rootState }, dateranges) => Promise.all(
            dateranges.map(
                daterange => axios.get("/report/tone", {
                    params: {
                        key: rootState.auth.user.key,
                        date_start: dateKey(daterange.start),
                        date_end: dateKey(daterange.end)
                    }
                })
            )
        ).then(
            responses => responses.map(
                ({ data: tone }, index) => ({ daterange: dateranges[index], tone })
            )
        ).then(dangers => commit("setDaterangeTones", dangers))
    }
};
