import Vue from "vue";
import axios from "axios";
import { daterangeKey, dateKey } from "@/helpers/daterange";

function unitInitialState() {
    return {
        modeId: "base",
        compareDaterange: { start: null, end: null },
        type: null,
        sourceId: "distribution",
        distributionId: null,
        viewId: "table",
        chartFieldId: "value",
        ordersTotalCount: null,
        ordersCurrentPage: 1,
        ordersPerPage: 50,
        pagesTotalCount: null,
        sorting: {
            distribution: {
                field: "value",
                direction: "DESC"
            },
            orders: {
                field: "date",
                direction: "ASC"
            }
        },
        data: {
            total: {},
            orders: []
        }
    };
}

export default {
    state: () => unitInitialState(),
    mutations: {
        setUnitParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        setUnitSorting(state, { table, field }) {
            if (state.sorting[table].field === field) {
                state.sorting[table].direction = state.sorting[table].direction === "ASC" ? "DESC" : "ASC";
            } else {
                state.sorting[table].field = field
            }
        },
        setUnitCompareDaterange(state, daterange) {
            state.compareDaterange = daterange;
        },
        setDaterangeTotalUnits(state, units) {
            units.forEach(({ daterange, unit }) => Vue.set(state.data.total, daterangeKey(daterange), unit));
        },
        setDaterangeUnitOrders(state, { orders, orders_total_count, pages_total_count }) {
            state.ordersTotalCount = orders_total_count;
            state.pagesTotalCount = pages_total_count;
            state.data.orders = orders;
        },
        resetDaterangeUnitOrders(state) {
            state.ordersTotalCount = null;
            state.pagesTotalCount = null;
            state.ordersCurrentPage = 1;
            state.data.orders = [];
        },
        resetUnitState(state) {
            Object.assign(state, unitInitialState());
        }
    },
    actions: {
        requestTotalUnits: ({ commit, rootState }, dateranges) => Promise.all(
            dateranges.map(
                daterange => axios.get("/report/unit", {
                    params: {
                        key: rootState.auth.user.key,
                        date_start: dateKey(daterange.start),
                        date_end: dateKey(daterange.end)
                    }
                })
            )
        ).then(
            responses => responses.map(
                ({ data: unit }, index) => ({ daterange: dateranges[index], unit })
            )
        ).then(units => commit("setDaterangeTotalUnits", units)),
        requestUnitOrders: ({ commit, state, rootState }, { daterange, id}) => axios.get(`/report/unit/${id}`, {
            params: {
                key: rootState.auth.user.key,
                date_start: dateKey(daterange.start),
                date_end: dateKey(daterange.end),
                sorting_field: state.sorting.orders.field,
                sorting_direction: state.sorting.orders.direction,
                orders_per_page: state.ordersPerPage,
                page: state.ordersCurrentPage
            }
        }).then(
            ({ data }) => commit("setDaterangeUnitOrders", data)
        )
    }
};
