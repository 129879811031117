import Vue from "vue";
import axios from "axios";
import { daterangeKey, dateKey } from "@/helpers/daterange";

function dishInitialState() {
    return {
        modeId: "base",
        itemsId: "groups",
        compareDaterange: { start: null, end: null },
        sorting: {
            field: "amount",
            direction: "DESC"
        },
        data: {
            dishes: {},
            groups: {}
        },
    };
}

export default {
    state: () => dishInitialState(),
    mutations: {
        setDishParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        setDishSorting(state, field) {
            if (state.sorting.field === field) {
                state.sorting.direction = state.sorting.direction === "ASC" ? "DESC" : "ASC";
            } else {
                state.sorting.field = field
            }
        },
        setDishCompareDaterange(state, daterange) {
            state.compareDaterange = daterange;
        },
        setDaterangeDishes(state, { dishes, itemsId }) {
            dishes.forEach(({ daterange, dishes }) => Vue.set(state.data[itemsId], daterangeKey(daterange), dishes));
        },
        resetDishState(state) {
            Object.assign(state, dishInitialState());
        }
    },
    actions: {
        requestDishes: ({ commit, rootState }, { dateranges, itemsId }) => {
            let params = {
                key: rootState.auth.user.key,
            };

            if (itemsId === "dishes") {
                params.type = "dish";
            }

            return Promise.all(
                dateranges.map(
                    daterange => axios.get("/report/dish", {
                        params: {
                            ...params,
                            date_start: dateKey(daterange.start),
                            date_end: dateKey(daterange.end)
                        }
                    })
                )
            ).then(
                responses => responses.map(
                    ({ data: dishes }, index) => {
                        return {
                            daterange: dateranges[index],
                            dishes
                        }
                    }
                )
            ).then(dishes => commit("setDaterangeDishes", { dishes, itemsId }));
        }
    }
};
