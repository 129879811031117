import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        isInNavbar: false,
        meta: {
            title: "Prosushi Dashboard",
            requiresAuth: true
        },
        redirect: {
            name: "IncomeTotal"
        }
    },
    {
        path: "/income",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "income" */ "@/views/Income.vue"),
        children: [
            {
                path: "",
                name: "IncomeTotal",
                meta: {
                    title: "Доходы",
                    navbarIcon: "bar-chart",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/income"]
                },
                component: () => import(/* webpackChunkName: "income-total" */ "@/views/income/IncomeTotal.vue")
            },
            {
                path: ":id",
                name: "IncomeRestaurant",
                meta: {
                    title: "Доходы ресторана",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/income"]
                },
                component: () => import(/* webpackChunkName: "income-restaurant" */ "@/views/income/IncomeRestaurant.vue"),
                props: true
            }
        ]
    },
    {
        path: "/load",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "load" */ "@/views/Load.vue"),
        children: [
            {
                path: "",
                name: "LoadTotal",
                meta: {
                    title: "Эффективность",
                    navbarIcon: "gauge",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/load"]
                },
                component: () => import(/* webpackChunkName: "load-total" */ "@/views/load/LoadTotal.vue")
            },
            {
                path: ":id",
                name: "LoadRestaurant",
                meta: {
                    title: "Эффективность ресторана",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/load"]
                },
                component: () => import(/* webpackChunkName: "load-restaurant" */ "@/views/load/LoadRestaurant.vue"),
                props: true
            }
        ]
    },
    {
        path: "/efficiency",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "efficiency" */ "@/views/Efficiency.vue"),
        children: [
            {
                path: "",
                name: "EfficiencyTotal",
                meta: {
                    title: "Эффективность 2",
                    navbarIcon: "efficiency",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/efficiency"]
                },
                component: () => import(/* webpackChunkName: "efficiency-total" */ "@/views/efficiency/EfficiencyTotal.vue")
            },
            {
                path: ":id",
                name: "EfficiencyRestaurant",
                meta: {
                    title: "Эффективность 2 ресторана",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/efficiency"]
                },
                component: () => import(/* webpackChunkName: "efficiency-restaurant" */ "@/views/efficiency/EfficiencyRestaurant.vue"),
                props: true
            }
        ]
    },
    {
        path: "/unit2",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "unit2" */ "@/views/Unit2.vue"),
        children: [
            {
                path: ":id?",
                name: "UnitDetail2",
                meta: {
                    title: "Unit-экономика",
                    navbarIcon: "piggy-bank",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/unit2"]
                },
                component: () => import(/* webpackChunkName: "unit-detail2" */ "@/views/unit2/UnitDetail2.vue"),
                props: true
            }
        ]
    },
    {
        path: "/timing",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "timing" */ "@/views/Timing.vue"),
        children: [
            {
                path: "",
                name: "TimingTotal",
                meta: {
                    title: "Время. Доставка",
                    navbarIcon: "clock-car",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/timing"]
                },
                component: () => import(/* webpackChunkName: "timing-total" */ "@/views/timing/TimingTotal.vue")
            },
            {
                path: ":id",
                name: "TimingRestaurant",
                meta: {
                    title: "Время. Доставка",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/timing"]
                },
                component: () => import(/* webpackChunkName: "timing-restaurant" */ "@/views/timing/TimingRestaurant.vue"),
                props: true
            }
        ]
    },
    {
        path: "/timing-hall",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "timing-hall" */ "@/views/TimingHall.vue"),
        children: [
            {
                path: "",
                name: "TimingHallTotal",
                meta: {
                    title: "Время. Зал",
                    navbarIcon: "dish-waiter",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/timing-hall"]
                },
                component: () => import(/* webpackChunkName: "timing-hall-total" */ "@/views/timing-hall/TimingHallTotal.vue")
            },
            {
                path: ":id",
                name: "TimingHallRestaurant",
                meta: {
                    title: "Время. Зал",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/timing-hall-detail"]
                },
                component: () => import(/* webpackChunkName: "timing-hall-restaurant" */ "@/views/timing-hall/TimingHallRestaurant.vue"),
                props: true
            }
        ]
    },
    {
        path: "/cooking",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "cooking" */ "@/views/Cooking.vue"),
        children: [
            {
                path: "",
                name: "CookingTotal",
                meta: {
                    title: "Время. Кухня",
                    navbarIcon: "clock-cooking",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/cooking"]
                },
                component: () => import(/* webpackChunkName: "cooking-total" */ "@/views/cooking/CookingTotal.vue")
            },
            {
                path: ":id",
                name: "CookingRestaurant",
                meta: {
                    title: "Время. Кухня",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/cooking"]
                },
                component: () => import(/* webpackChunkName: "cooking-restaurant" */ "@/views/cooking/CookingRestaurant.vue"),
                props: true
            }
        ]
    },
    {
        path: "/trip",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "trip" */ "@/views/Trip.vue"),
        children: [
            {
                path: "",
                name: "TripTotal",
                meta: {
                    title: "Рейсы",
                    navbarIcon: "car",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/trip"]
                },
                component: () => import(/* webpackChunkName: "trip-total" */ "@/views/trip/TripTotal.vue")
            },
            {
                path: ":id",
                name: "TripRestaurant",
                meta: {
                    title: "Рейсы ресторана",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/trip"]
                },
                component: () => import(/* webpackChunkName: "trip-restaurant" */ "@/views/trip/TripRestaurant.vue"),
                props: true
            }
        ]
    },
    {
        path: "/tone",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "tone" */ "@/views/Tone.vue"),
        children: [
            {
                path: ":id?",
                name: "ToneDetail",
                meta: {
                    title: "Тональность",
                    navbarIcon: "pie-chart",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/tone"]
                },
                component: () => import(/* webpackChunkName: "tone-detail" */ "@/views/tone/ToneDetail.vue"),
                props: true
            }
        ]
    },
    {
        path: "/attestation",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "attestation" */ "@/views/Attestation.vue"),
        children: [
            {
                path: "",
                name: "AttestationTotal",
                meta: {
                    title: "Аттестация",
                    navbarIcon: "check-list",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/validation"]
                },
                component: () => import(/* webpackChunkName: "attestation-total" */ "@/views/attestation/AttestationTotal.vue")
            },
            {
                path: ":id",
                name: "AttestationDepartment",
                meta: {
                    title: "Аттестация подразделения",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/validation"]
                },
                component: () => import(/* webpackChunkName: "attestation-department" */ "@/views/attestation/AttestationDepartment.vue"),
                props: true
            }
        ]
    },
    {
        path: "/staff",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "staff" */ "@/views/Staff.vue"),
        children: [
            {
                path: "",
                name: "StaffTotal",
                meta: {
                    title: "Штатная расстановка",
                    navbarIcon: "card",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/ranking"]
                },
                component: () => import(/* webpackChunkName: "staff-total" */ "@/views/staff/StaffTotal.vue")
            },
            {
                path: ":id",
                name: "StaffDepartment",
                meta: {
                    title: "Штатная расстановка подразделения",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/ranking"]
                },
                component: () => import(/* webpackChunkName: "staff-department" */ "@/views/staff/StaffDepartment.vue"),
                props: true
            }
        ]
    },
    {
        path: "/danger",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "danger" */ "@/views/Danger.vue"),
        children: [
            {
                path: "",
                name: "DangerTotal",
                meta: {
                    title: "Опасные операции",
                    navbarIcon: "fire",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/danger"]
                },
                component: () => import(/* webpackChunkName: "danger-total" */ "@/views/danger/DangerTotal.vue")
            },
            {
                path: ":id",
                name: "DangerRestaurant",
                meta: {
                    title: "Опасные операции ресторана",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/danger"]
                },
                component: () => import(/* webpackChunkName: "danger-restaurant" */ "@/views/danger/DangerRestaurant.vue"),
                props: true
            }
        ]
    },
    {
        path: "/dish",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "dish" */ "@/views/Dish.vue"),
        children: [
            {
                path: "",
                name: "DishTotal",
                meta: {
                    title: "Блюда",
                    navbarIcon: "dish",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/dish"]
                },
                component: () => import(/* webpackChunkName: "dish-total" */ "@/views/dish/DishTotal.vue")
            },
            {
                path: ":id",
                name: "DishRestaurant",
                meta: {
                    title: "Блюда ресторана",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/dish"]
                },
                component: () => import(/* webpackChunkName: "dish-restaurant" */ "@/views/dish/DishRestaurant.vue"),
                props: true
            }
        ]
    }, {
        path: "/usage",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "usage" */ "@/views/Usage.vue"),
        children: [
            {
                path: "",
                name: "UsageTotal",
                meta: {
                    title: "Использование",
                    navbarIcon: "people",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/usage"]
                },
                component: () => import(/* webpackChunkName: "usage-total" */ "@/views/usage/UsageTotal.vue")
            },
            {
                path: ":id",
                name: "UsageUser",
                meta: {
                    title: "Использование",
                    requiresAuth: true,
                    requiredMethodURLs: ["report/usage"]
                },
                component: () => import(/* webpackChunkName: "usage-restaurant" */ "@/views/usage/UsageUser.vue"),
                props: true
            }
        ]
    },
    {
        path: "/settings",
        isInNavbar: true,
        component: () => import(/* webpackChunkName: "settings" */ "@/views/Settings.vue"),
        children: [
            {
                path: "",
                name: "Settings",
                meta: {
                    title: "Настройки",
                    navbarIcon: "gear",
                    requiresAuth: true,
                }
            },
            {
                path: "income",
                name: "SettingsIncome",
                meta: {
                    title: "Настройки",
                    tabTitle: "План доходов",
                    requiresAuth: true,
                    requiredMethodURLs: ["get/income", "set/income"]
                },
                component: () => import(/* webpackChunkName: "settings-income" */ "@/views/settings/SettingsIncome.vue")
            },
            {
                path: "payments",
                name: "SettingsPayments",
                meta: {
                    title: "Настройки",
                    tabTitle: "Расходы ресторана",
                    requiresAuth: true,
                    requiredMethodURLs: ["get/payments", "set/payments"]
                },
                component: () => import(/* webpackChunkName: "settings-payments" */ "@/views/settings/SettingsPayments.vue")
            },
            {
                path: "wages",
                name: "SettingsWages",
                meta: {
                    title: "Настройки",
                    tabTitle: "ФОТ ресторана",
                    requiresAuth: true,
                    requiresAdmin: true
                },
                component: () => import(/* webpackChunkName: "settings-wages" */ "@/views/settings/SettingsWages.vue")
            },
            {
                path: "comissions",
                name: "SettingsComissions",
                meta: {
                    title: "Настройки",
                    tabTitle: "Комиссии",
                    requiresAuth: true,
                    requiresAdmin: true
                },
                component: () => import(/* webpackChunkName: "settings-comissions" */ "@/views/settings/SettingsComissions.vue")
            },
            {
                path: "timing",
                name: "SettingsTiming",
                meta: {
                    title: "Настройки",
                    tabTitle: "Нормы тайминга",
                    requiresAuth: true,
                    requiredMethodURLs: ["get/timing", "set/timing"]
                },
                component: () => import(/* webpackChunkName: "settings-timing" */ "@/views/settings/SettingsTiming.vue")
            },
            {
                path: "users",
                name: "SettingsUsers",
                meta: {
                    title: "Настройки",
                    tabTitle: "Пользователи",
                    requiresAuth: true,
                    requiresAdmin: true
                },
                component: () => import(/* webpackChunkName: "settings-users" */ "@/views/settings/SettingsUsers.vue")
            },
        ]
    },
    {
        path: "/news",
        isInNavbar: true,
        name: "News",
        meta: {
            title: "Новости",
            navbarIcon: "text-bubble",
            requiresAuth: true,
            requiresAdmin: true
        },
        component: () => import(/* webpackChunkName: "news" */ "@/views/News.vue")
    },
    {
        path: "/login",
        isInNavbar: false,
        name: "Login",
        meta: {
            title: "Авторизация",
            requiresGuest: true
        },
        component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
    },
    {
        path: "/401",
        isInNavbar: false,
        name: "Error401",
        meta: {
            title: "Ошибка 401",
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "error-401" */ "@/views/Error401.vue"),
        props: true
    },
    {
        path: "/404",
        isInNavbar: false,
        name: "Error404",
        meta: {
            title: "Ошибка 404",
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "error-404" */ "@/views/Error404.vue")
    },
    {
        path: "*",
        redirect: "/404"
    }
]

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (to.hash) {
            return {
                selector: to.hash
            }
        }

        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (store.getters.isAuthenticated) {
            next();
            return;
        } else {
            next("/login");
        }

    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresGuest) {
        if (store.getters.isAuthenticated) {
            next("/");
            return;
        } else {
            next();
        }

    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (store.getters.isAdmin) {
            next();
            return;
        } else {
            next({
                name: "Error401",
                params: {
                    requestedResource: to.meta.title
                }
            });
        }
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiredMethodURLs) {
        if (
            to.meta.requiredMethodURLs.every(
                methodURL => store.getters.userMethods.URLs.includes(methodURL)
            )
        ) {
            next();
            return;
        } else {
            next({
                name: "Error401",
                params: {
                    requestedResource: to.meta.title
                }
            });
        }
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (store.state.ui.version === "mobile" && store.state.ui.isNavbarOpened) {
        store.commit("toggleNavbar", false);
    }

    if (to.meta.title) {
        store.commit("setTitle", to.meta.title);
    }

    next();
});

export default router;
