import Vue from "vue";
import axios from "axios";
import { daterangeKey, dateKey } from "@/helpers/daterange";

function efficiencyInitialState() {
    return {
        data: {},
        modeId: "base",
        compareDaterange: { start: null, end: null }
    };
}

export default {
    state: () => efficiencyInitialState(),
    mutations: {
        setEfficiencyMode(state, id) {
            state.modeId = id;
        },
        setEfficiencyCompareDaterange(state, daterange) {
            state.compareDaterange = daterange;
        },
        setDaterangeEfficiencies(state, efficiencies) {
            efficiencies.forEach(({ daterange, restaurantsKey, efficiency }) => Vue.set(state.data, `${daterangeKey(daterange)}-${restaurantsKey}`, efficiency));
        },
        resetEfficiencyState(state) {
            Object.assign(state, efficiencyInitialState());
        }
    },
    actions: {
        requestEfficiencies: ({ commit, rootState }, { dateranges, restaurants, restaurantsKey }) => Promise.all(
            dateranges.map(
                daterange => axios.get("/report/efficiency", {
                    params: {
                        key: rootState.auth.user.key,
                        date_start: dateKey(daterange.start),
                        date_end: dateKey(daterange.end),
                        restaurants: restaurants?.map(({ title }) => title),
                    }
                })
            )
        ).then(
            responses => responses.map(
                ({ data: efficiency }, index) => ({ daterange: dateranges[index], restaurantsKey, efficiency })
            )
        ).then(efficiencies => commit("setDaterangeEfficiencies", efficiencies))
    }
};
