import Vue from "vue";
import axios from "axios";
import { daterangeKey, dateKey } from "@/helpers/daterange";

function timingHallInitialState() {
    return {
        modes: [
            { id: "base", title: "Данные периода" },
            { id: "compareOtherPeriod", title: "Сравнение периодов" },
        ],
        modeId: "base",
        groupKitchenDepartments: true,
        compareDaterange: { start: null, end: null },
        sorting: {
            field: "time_order",
            direction: "DESC"
        },
        ordersTotalCount: null,
        ordersCurrentPage: 1,
        ordersPerPage: 50,
        pagesTotalCount: null,
        data: {
            total: {},
            orders: {
                report: {},
                compare: {},
            }
        }
    };
}

export default {
    state: () => timingHallInitialState(),
    mutations: {
        setTimingHallParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        setTimingHallSorting(state, field) {
            if (state.sorting.field === field) {
                state.sorting.direction = state.sorting.direction === "ASC" ? "DESC" : "ASC";
            } else {
                state.sorting.field = field
            }
        },
        setDaterangeHallTimings(state, timings) {
            timings.forEach(({ daterange, restaurantsKey, timing }) => Vue.set(state.data.total, `${daterangeKey(daterange)}-${restaurantsKey}`, timing));
        },
        setDaterangeHallTimingsOrders(state, { orders, orders_total_count, pages_total_count }) {
            state.ordersTotalCount = orders_total_count;
            state.pagesTotalCount = pages_total_count;
            state.data.orders = orders;
        },
        resetDaterangeHallTimingsOrders(state) {
            state.ordersTotalCount = null;
            state.pagesTotalCount = null;
            state.ordersCurrentPage = 1;
            state.data.orders = [];
        },
        resetTimingHallState(state) {
            Object.assign(state, timingHallInitialState());
        }
    },
    actions: {
        requestHallTimings: ({ commit, rootState }, { dateranges, restaurants, restaurantsKey }) => Promise.all(
            dateranges.map(
                daterange => axios.get("/report/timing-hall", {
                    params: {
                        key: rootState.auth.user.key,
                        date_start: dateKey(daterange.start),
                        date_end: dateKey(daterange.end),
                        restaurants: restaurants?.map(({ title }) => title),
                    }
                })
            )
        ).then(
            responses => responses.map(
                ({ data: timing }, index) => ({ daterange: dateranges[index], restaurantsKey, timing })
            )
        ).then(timings => commit("setDaterangeHallTimings", timings)),
        requestTimingHallOrders: ({ commit, state, rootState }, { daterange, id }) => axios.get(`/report/unit/${id}`, {
            params: {
                key: rootState.auth.user.key,
                date_start: dateKey(daterange.start),
                date_end: dateKey(daterange.end),
                sorting_field: state.sorting.orders.field,
                sorting_direction: state.sorting.orders.direction,
                orders_per_page: state.ordersPerPage,
                page: state.ordersCurrentPage
            }
        }).then(
            ({ data }) => commit("setDaterangeUnitOrders", data)
        )
    }
};
