import { nanoid } from "nanoid";

export default {
    state: () => ({
        version: null,
        isNavbarOpened: false,
        isReportTotalLinkVisible: false,
        isShowRestaurantsFilter: true,
        messages: []
    }),
    mutations: {
        setVersion(state, version) {
            state.version = version;
        },
        toggleNavbar(state, isNavbarOpened) {
            state.isNavbarOpened = isNavbarOpened;
        },
        toggleReportTotalLink(state, isReportTotalLinkVisible) {
            state.isReportTotalLinkVisible = isReportTotalLinkVisible;
        },
        toggleRestaurantsFilter(state, isShowRestaurantsFilter) {
            state.isShowRestaurantsFilter = isShowRestaurantsFilter;
        },
        addMessage(state, message) {
            state.messages.push(message);
        },
        removeMessage(state, id) {
            state.messages = state.messages.filter(message => message.id !== id);
        }
    },
    actions: {
        createMessage: ({ commit }, message ) => {
            message.id = nanoid();

            setTimeout(() => {
                commit("removeMessage", message.id);
            }, 3000);

            commit("addMessage", message);
        }
    }
};
