/* Vue */
import Vue from "vue";
Vue.config.productionTip = false;


/* Стили */
import "./scss/common.scss";


/* Настройки axios */
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

if (process.env.NODE_ENV === "production") {
    axios.defaults.withCredentials = true;
    axios.defaults.auth = {
        username: process.env.VUE_APP_API_USERNAME,
        password: process.env.VUE_APP_API_PASSWORD
    };
}


/* Глобальные фильтры */
import * as filters from "./helpers/filters";
Object.entries(filters).map(([filterName, filterFn]) => Vue.filter(filterName, filterFn));


/* Глобальные компоненты */
import SvgIcon from "./components/SvgIcon";
import Spinner from "@/components/Spinner";
import ErrorMessage from "@/components/ErrorMessage";
import Radio from "@/components/Radio";
import Dashboard from "./Dashboard";

Vue.component("svg-icon", SvgIcon);
Vue.component("spinner", Spinner);
Vue.component("error-message", ErrorMessage);
Vue.component("radio", Radio);

/* Глобальные сторонние компоненты */
import VModal from "vue-js-modal";
Vue.use(VModal);

import VCalendar from "v-calendar";
Vue.use(VCalendar, {
    screens: { desktop },
    locales: {
        "ru": {
            masks: { weekdays: "WW" }
        }
    }
});

import HighchartsVue from "highcharts-vue";
Vue.use(HighchartsVue);

import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
Vue.component("popper", Popper);

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
Vue.component("multiselect", Multiselect);

import ToggleButton from "vue-js-toggle-button";
Vue.use(ToggleButton);


/* Main Vue Instance */
import router from "./router";
import store from "./store";
import { mapGetters, mapMutations } from "vuex";
import { desktop } from "./scss/_variables.scss";

new Vue({
    router,
    store,
    render: h => h(Dashboard),
    computed: {
        ...mapGetters({
            allRestaurants: "userRestaurantsList"
        }),
    },
    methods: {
        ...mapMutations([
            "setVersion",
            "setReportParameter",
        ]),
        getVersion({ matches: isDesktop }) {
            return isDesktop ? "desktop" : "mobile";
        }
    },
    created() {
        let desktopMediaQuery = window.matchMedia(`(min-width: ${desktop})`);
        this.setVersion(this.getVersion(desktopMediaQuery));

        desktopMediaQuery.addEventListener("change", event => {
            this.setVersion(this.getVersion(event));
        });

        this.setReportParameter({
            parameter: "restaurants",
            value: this.allRestaurants
        });
    }
}).$mount(".dashboard");
