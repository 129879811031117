<template>
    <label class="radio">
        <input class="radio__input" type="radio" :name="name" :disabled="disabled" :value="val" v-model="model">
        <span class="radio__label"><slot></slot></span>
    </label>
</template>

<script>
    export default{
        name: "Radio",
        props: {
            name: {
                type: String,
                required: true
            },
            val: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            model: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                }
            }
        }
    }
</script>

<style lang="scss">
    .radio {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
    }
    .radio__input {
        margin-right: 5px;
    }
    .radio__label {
        line-height: 1;
    }
</style>
