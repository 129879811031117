<template>
<article class="page">
    <header class="page__header">
        <div class="page__header-info">
            <h1 class="page__title h1">{{title}}</h1>
            <div class="page__header-user" v-if="isAuthenticated">
                <span class="page__header-username">{{user.name}}</span>
                (<button class="page__header-logout link link_red" @click="logout">выйти</button>)
            </div>
        </div>
    </header>
    <router-view class="page__content" />
    <messages class="page__messages" />
</article>
</template>

<script>
    import { mapState, mapGetters, mapActions } from "vuex";
    import Messages from "@/components/Messages";

    export default {
        name: "Page",
        components: {
            Messages
        },
        computed: {
            ...mapState({
                title: state => state.page.title
            }),
            ...mapGetters([
                "isAuthenticated",
                "user"
            ])
        },
        methods: {
            ...mapActions(["logout"])
        }
    }
</script>

<style lang="scss">
    .page__header {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        padding: 10px 8px;
        background-color: $background-gray-primary;

        @include desktop {
            padding: 20px 30px 10px;

            position: sticky;
            top: 0;
            z-index: 101;
        }
    }
    .page__content {
        padding: 0 8px 10px;

        @include desktop {
            padding: 0 30px 25px;
        }
    }
    .page__header-info {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .page__header-user {
        margin-left: auto;
    }
    .page__messages {
        position: fixed;
        bottom: 20px;
        right: 40px;
    }
</style>
