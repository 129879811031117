import Vue from "vue";
import axios from "axios";
import { daterangeFromStartOfMonthToToday, daterangeKey, dateKey } from "@/helpers/daterange";

function usageInitialState() {
    return {
        data: {
            users: {},
            actions: {}
        },
        filters: {
            total: {
                users: "all",
                methods: "all",
            },
            user: {
                methods: "all",
            }
        },
        sortings: {
            total: {
                field: "userName",
                direction: "ASC"
            },
            user: {
                field: "methodTitle",
                direction: "ASC"
            }
        },
        modeId: "base",
        reportDaterange: daterangeFromStartOfMonthToToday(),
        compareDaterange: { start: null, end: null },
    };
}

export default {
    state: () => usageInitialState(),
    mutations: {
        setUsageParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        setUsageFilter(state, { table, filter, value }) {
            state.filters[table][filter] = value;
        },
        setUsageSorting(state, { table, field }) {
            if (state.sortings[table].field === field) {
                state.sortings[table].direction = state.sortings[table].direction === "ASC" ? "DESC" : "ASC";
            } else {
                state.sortings[table].field = field
            }
        },
        setUsageReportDaterange(state, daterange) {
            state.reportDaterange = daterange;
        },
        setUsageCompareDaterange(state, daterange) {
            state.compareDaterange = daterange;
        },
        setDaterangeUsages(state, { usages, type }) {
            usages.forEach(({ daterange, usage }) => Vue.set(state.data[type], daterangeKey(daterange), usage));
        },
        resetUsageState(state) {
            Object.assign(state, usageInitialState());
        }
    },
    actions: {
        requestUsages: ({ commit, rootState }, { dateranges, type }) => {
            let params = {
                key: rootState.auth.user.key,
            };

            if (type === "users") {
                params.group = 1;
            }

            return Promise.all(
                dateranges.map(
                    daterange => axios.get("/report/usage", {
                        params: {
                            ...params,
                            date_start: dateKey(daterange.start),
                            date_end: dateKey(daterange.end)
                        }
                    })
                )
            ).then(
                responses => responses.map(
                    ({ data: usage }, index) => {
                        usage = usage.filter(({ user_id }) => user_id !== 1);

                        return {
                            daterange: dateranges[index],
                            usage
                        }
                    }
                )
            ).then(usages => commit("setDaterangeUsages", { usages, type }));
        }
    }
};
