import Vue from "vue";
import axios from "axios";
import { daterangeKey, dateKey } from "@/helpers/daterange";

function dangerInitialState() {
    return {
        modeId: "base",
        viewId: "details",
        compareDaterange: { start: null, end: null },
        sorting: {
            restaurants: {
                group: "total",
                field: "sum",
                direction: "DESC"
            },
            details: {
                field: "date",
                direction: "ASC"
            }
        },
        data: {}
    };
}

export default {
    state: () => dangerInitialState(),
    mutations: {
        setDangerParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        setDangerRestaurantSorting(state, { group, field }) {
            if (state.sorting.restaurants.group === group && state.sorting.restaurants.field === field) {
                state.sorting.restaurants.direction = state.sorting.restaurants.direction === "ASC" ? "DESC" : "ASC";
            } else if (state.sorting.restaurants.group !== group && state.sorting.restaurants.field === field) {
                state.sorting.restaurants.group = group;
            } else if (state.sorting.restaurants.group === group && state.sorting.restaurants.field !== field) {
                state.sorting.restaurants.field = field;
            } else {
                state.sorting.restaurants.group = group;
                state.sorting.restaurants.field = field;
            }
        },
        setDangerDetailSorting(state, field) {
            if (state.sorting.details.field === field) {
                state.sorting.details.direction = state.sorting.details.direction === "ASC" ? "DESC" : "ASC";
            } else {
                state.sorting.details.field = field
            }
        },
        setDaterangeDangers(state, dangers) {
            dangers.forEach(({ daterange, restaurantsKey, danger }) => Vue.set(state.data, `${daterangeKey(daterange)}-${restaurantsKey}`, danger));
        },
        resetDangerState(state) {
            Object.assign(state, dangerInitialState());
        }
    },
    actions: {
        requestDangers: ({ commit, rootState }, { dateranges, restaurants, restaurantsKey }) => Promise.all(
            dateranges.map(
                daterange => axios.get("/report/danger", {
                    params: {
                        key: rootState.auth.user.key,
                        date_start: dateKey(daterange.start),
                        date_end: dateKey(daterange.end),
                        restaurants: restaurants?.map(({ title }) => title),
                    }
                })
            )
        ).then(
            responses => responses.map(
                ({ data: danger }, index) => ({ daterange: dateranges[index], restaurantsKey, danger })
            )
        ).then(dangers => commit("setDaterangeDangers", dangers))
    }
};


