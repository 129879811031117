import Vue from "vue";
import axios from "axios";
import { daterangeKey, dateKey } from "@/helpers/daterange";

function timingInitialState() {
    return {
        data: {},
        zoneId: "all",
        modeId: "averageTime",
        showLateDistributionSelect: true,
        lateDistributionItemId: "total",
    };
}

export default {
    state: () => timingInitialState(),
    mutations: {
        setTimingParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        setDaterangeTiming(state, { daterange, zone, timing }) {
            Vue.set(state.data, `${daterangeKey(daterange)}-${zone}`, timing);
        },
        resetTimingState(state) {
            Object.assign(state, timingInitialState());
        }
    },
    actions: {
        requestTiming: ({ commit, rootState }, { daterange, zone = "all" }) => axios.get("/report/timing", {
            params: {
                key: rootState.auth.user.key,
                date_start: dateKey(daterange.start),
                date_end: dateKey(daterange.end),
                zone,
            }
        }).then(
            ({ data: timing }) => commit("setDaterangeTiming", { daterange, zone, timing })
        )
    }
};
