import Vue from "vue";
import axios from "axios";
import startOfMonth from "date-fns/startOfMonth";
import dateKey from "@/helpers/daterange";

let firstDayOfMonth = startOfMonth(new Date());

function settingsInitialState() {
    return {
        income: {
            plan: {
                date: firstDayOfMonth,
                data: {}
            }
        },
        timing: {
            standard: {
                modes: [
                    {
                        id: "byMonth",
                        title: "Для конкретного месяца",
                        tip: "Время вводится в минутах."
                    },
                    {
                        id: "default",
                        title: "Значения по умолчанию",
                        tip: "Значения, которые будут использоваться в качестве норм для новых месяцев. Время вводится в минутах."
                    }
                ],
                modeId: "byMonth",
                timezones: [
                    {
                        id: 45,
                        title: "45 минут"
                    },
                    {
                        id: 60,
                        title: "60 минут"
                    },
                    {
                        id: 90,
                        title: "90 минут"
                    },
                ],
                timezoneId: 60,
                date: firstDayOfMonth,
                data: {}
            }
        },
        payments: {
            date: firstDayOfMonth,
            data: {}
        },
        comissions: [],
        wages: {
            data: {},
            date: firstDayOfMonth,
            restaurant: null,
        }
    };
}

export default {
    state: () => settingsInitialState(),
    getters: {
        areComissionsLoaded: state => state.comissions.length > 0,
    },
    mutations: {
        setIncomePlanDate(state, date) {
            state.income.plan.date = date;
        },
        setIncomePlanData(state, { date, data }) {
            Vue.set(state.income.plan.data, date, data);
        },
        updateRestaurantIncomePlanSum(state, { date, restaurantId, sum }) {
            state.income.plan.data[date].find(({ id }) => id === restaurantId).sum = sum;
        },
        setTimingStandardParameter(state, { parameter, value}) {
            state.timing.standard[parameter] = value;
        },
        setTimingStandardData(state, { time_zone, date, data }) {
            Vue.set(state.timing.standard.data, `${date ?? "default"}-${time_zone}`, data);
        },
        setPaymentsDate(state, date) {
            state.payments.date = date;
        },
        setPaymentsData(state, { date, data }) {
            Vue.set(state.payments.data, date, data);
        },
        setPaymentsRestaurantData(state, { date, eng, key, ...data }) {
            let restaurant = state.payments.data[date].find(({ id }) => id === eng);

            Object.keys(data).forEach(parameter => {
                restaurant[parameter] = data[parameter];
            });
        },
        setComissionsInState(state, comissions) {
            Vue.set(state, "comissions", comissions);
        },
        deleteComissionFromState(state, comissionToDeleteId) {
            state.comissions = state.comissions.filter(({ id }) => id !== comissionToDeleteId);
        },
        updateComissionInState(state, comission) {
            let comissionIndex = state.comissions.findIndex(({ id }) => id === comission.id);
            state.comissions.splice(comissionIndex, 1, comission);
        },
        addComissionToState(state, comission) {
            state.comissions.push(comission);
        },
        setWageParameter(state, { parameter, value }) {
            state.wages[parameter] = value;
        },
        setWagesInState(state, { wages, date }) {
            Vue.set(state.wages.data, date, wages);
        },
        updateWageInState(state, { date, wage }) {
            console.log(date)
            console.log(wage)
            let wageIndex = state.wages.data?.[date]?.findIndex(({ id }) => id === wage.id);
            state.wages.data?.[date]?.splice(wageIndex, 1, wage);
        },
        resetSettingsState(state) {
            Object.assign(state, settingsInitialState());
        },
    },
    actions: {
        requestIncomePlan: ({ commit, rootState }, date) => axios.get("/get/income", {
            params: {
                key: rootState.auth.user.key,
                date
            }
        }).then(
            ({ data }) => commit("setIncomePlanData", { date, data })
        ),
        saveRestaurantIncomePlanToDatabase: ({ commit, rootState }, { date, eng, sum }) => Promise.all([
            new Promise(resolve => setTimeout(resolve, 1000)),
            axios.get("/set/income", {
                params: { key: rootState.auth.user.key, eng, date, sum }
            })
        ]).then(
            () => {
                commit("resetIncomeState");
                commit("updateRestaurantIncomePlanSum", {
                    date,
                    restaurantId: eng,
                    sum: Number(sum)
                });
            }
        ),
        requestTimingStandard: ({ commit, rootState }, { time_zone, date }) => {
            let params = {
                key: rootState.auth.user.key,
                time_zone
            };

            if (date) {
                params.date = date;
            }

            return axios.get("/get/timing", { params }).then(
                ({ data }) => commit("setTimingStandardData", { time_zone, date, data })
            );
        },
        saveTimingStandardToDatabase: ({ commit, rootState }, parameters) => Promise.all([
            new Promise(resolve => setTimeout(resolve, 500)),
            axios.get("/set/timing", {
                params: {
                    key: rootState.auth.user.key,
                    ...parameters
                }
            })
        ]).then(() => {
                let { time_zone, date, cooking_duration, stand_duration, delivery_duration, return_duration } = parameters;

                commit("setTimingStandardData", { time_zone, date, data: {
                    cooking_duration,
                    stand_duration,
                    delivery_duration,
                    return_duration,
                }});
            }
        ),
        requestPayments: ({ commit, rootState }, date) => axios.get("/get/payments", {
            params: {
                key: rootState.auth.user.key,
                date
            }
        }).then(
            ({ data }) => commit("setPaymentsData", { date, data })
        ),
        savePaymentsToDB: ({ commit, rootState }, parameters) => {
            let params = {
                key: rootState.auth.user.key,
                ...parameters
            };

            return Promise.all([
                new Promise(resolve => setTimeout(resolve, 1000)),
                axios.get("/set/payments", { params })
            ]).then(
                () => {
                    commit("resetUnitState");
                    commit("setPaymentsRestaurantData", params);
                }
            );
        },
        getComissionsFromDB: ({ commit, rootState }) => axios.get("/get/comissions", {
            params: { key: rootState.auth.user.key }
        }).then(({ data: comissions }) => commit("setComissionsInState", comissions)),
        deleteComissionFromDB: ({ commit, rootState }, id) => axios.get("/set/comission/delete", {
            params: {
                key: rootState.auth.user.key,
                id
            }
        }).then(() => commit("deleteComissionFromState", id)),
        updateComissionInDB: ({ commit, rootState }, { id, updates }) => axios.get("/set/comission/update", {
            params: {
                key: rootState.auth.user.key,
                id,
                ...updates
            }
        }).then(({ data: { comission }}) => commit("updateComissionInState", comission)),
        addComissionToDB: ({ commit, rootState }, comissionData) => axios.get("/set/comission/add", {
            params: {
                key: rootState.auth.user.key,
                ...comissionData
            }
        }).then(({ data: { comission }}) => commit("addComissionToState", comission)),
        getWagesFromDB: ({ commit, rootState }, date) => axios.get("/get/wage", {
            params: {
                key: rootState.auth.user.key,
                date,
            }
        }).then(({ data: wages }) => commit("setWagesInState", { wages, date })),
        updateWagesInDB: ({ commit, rootState }, updates) => Promise.all(
            updates.map(
                update => axios.get("/set/wage", {
                    params: {
                        key: rootState.auth.user.key,
                        ...update
                    }
                })
            )
        ).then(
            responses => responses.forEach(({ data: { wage } }, index) => {
                commit("updateWageInState", { date: updates[index].date, wage });
            })
        )
    }
};
