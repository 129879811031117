import Vue from "vue";
import Vuex from "vuex";

import page from "./page";
import ui from "./ui";
import auth from "./auth";
import directories from "./directories";
import report from "./report";
import income from "./income";
import load from "./load";
import efficiency from "./efficiency";
import unit from "./unit";
import unit2 from "./unit2";
import timing from "./timing";
import timingHall from "./timing-hall";
import cooking from "./cooking";
import trip from "./trip";
import tone from "./tone";
import attestation from "./attestation";
import staff from "./staff";
import danger from "./danger";
import usage from "./usage";
import dish from "./dish";
import settings from "./settings";


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        page,
        ui,
        auth,
        directories,
        report,
        income,
        load,
        efficiency,
        unit,
        unit2,
        timing,
        timingHall,
        cooking,
        trip,
        tone,
        attestation,
        staff,
        danger,
        usage,
        dish,
        settings
    }
});
