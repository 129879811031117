import Vue from "vue";
import axios from "axios";
import { daterangeKey, dateKey } from "@/helpers/daterange";

function cookingInitialState() {
    return {
        data: {},
        modeId: "base",
        viewId: "table",
        serviceTypeId: "total",
        durationTypeId: "avg_duration",
        chartDepartmentId: null,
        sorting: {
            total: {
                field: "title"
            },
            restaurant: {
                field: "title"
            },
            direction: "ASC"
        },
        compareDaterange: { start: null, end: null },
    };
}

export default {
    state: () => cookingInitialState(),
    mutations: {
        setCookingParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        setCookingSorting(state, { table, field }) {
            if (state.sorting[table].field === field) {
                state.sorting.direction = state.sorting.direction === "ASC" ? "DESC" : "ASC";
            } else {
                state.sorting[table].field = field
            }
        },
        setCookingCompareDaterange(state, daterange) {
            state.compareDaterange = daterange;
        },
        setDaterangeCookings(state, cookings) {
            cookings.forEach(({ daterange, cooking }) => Vue.set(state.data, daterangeKey(daterange), cooking));
            state.chartDepartmentId = cookings[0].cooking.total.departments[0].id;
        },
        resetCookingState(state) {
            Object.assign(state, cookingInitialState());
        }
    },
    actions: {
        requestCookings: ({ commit, rootState }, dateranges) => Promise.all(
            dateranges.map(
                daterange => axios.get("/report/cooking", {
                    params: {
                        key: rootState.auth.user.key,
                        date_start: dateKey(daterange.start),
                        date_end: dateKey(daterange.end)
                    }
                })
            )
        ).then(
            responses => responses.map(
                ({ data: cooking }, index) => ({ daterange: dateranges[index], cooking })
            )
        ).then(cookings => commit("setDaterangeCookings", cookings))
    }
};
