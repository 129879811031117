import Vue from "vue";
import axios from "axios";
import { daterangeKey, dateKey } from "@/helpers/daterange";

function loadInitialState() {
    return {
        data: {},
        modeId: "base",
        compareDaterange: { start: null, end: null }
    };
}

export default {
    state: () => loadInitialState(),
    mutations: {
        setLoadMode(state, id) {
            state.modeId = id;
        },
        setLoadCompareDaterange(state, daterange) {
            state.compareDaterange = daterange;
        },
        setDaterangeLoads(state, loads) {
            loads.forEach(({ daterange, restaurantsKey, load }) => Vue.set(state.data, `${daterangeKey(daterange)}-${restaurantsKey}`, load));
        },
        resetLoadState(state) {
            Object.assign(state, loadInitialState());
        }
    },
    actions: {
        requestLoads: ({ commit, rootState }, { dateranges, restaurants, restaurantsKey }) => Promise.all(
            dateranges.map(
                daterange => axios.get("/report/load", {
                    params: {
                        key: rootState.auth.user.key,
                        date_start: dateKey(daterange.start),
                        date_end: dateKey(daterange.end),
                        restaurants: restaurants?.map(({ title }) => title),
                    }
                })
            )
        ).then(
            responses => responses.map(
                ({ data: load }, index) => ({ daterange: dateranges[index], restaurantsKey, load })
            )
        ).then(loads => commit("setDaterangeLoads", loads))
    }
};
