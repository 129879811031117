<template>
    <div class="error-message">
        <p class="error-message__heading h2">
            <slot>Ошибка загрузки данных.</slot>
        </p>
        <p class="error-message__text">
            Пожалуйста, <button class="error-message__reload-btn link link_red" @click="reload">обновите страницу</button>.
        </p>
    </div>
</template>

<script>
    export default{
        name: "ErrorMessage",
        methods: {
            reload() {
                this.$router.go();
            }
        }
    }
</script>

<style lang="scss">
    .error-message__heading {
        margin-bottom: 15px;
    }
</style>
