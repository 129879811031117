import Vue from "vue";
import axios from "axios";
import { daterangeKey, daterangeDaysCount, isDaterangeSet, dateKey } from "@/helpers/daterange";

function incomeInitialState() {
    return {
        modes: [
            { id: "base", title: "Базовые данные" },
            { id: "compareOtherPeriod", title: "Сравнение с другим периодом" },
            { id: "comparePlan", title: "Сравнение с планом" },
            { id: "dynamic", title: "Динамика" },
        ],
        modeId: "base",
        compareDaterange: { start: null, end: null },
        dynamicDaterange: { start: null, end: null },
        sorting: {
            field: "total",
            direction: "DESC"
        },
        data: {},
    };
}

export default {
    state: () => incomeInitialState(),
    mutations: {
        setIncomeParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        setIncomeSorting(state, field) {
            if (state.sorting.field === field) {
                state.sorting.direction = state.sorting.direction === "ASC" ? "DESC" : "ASC";
            } else {
                state.sorting.field = field
            }
        },
        setDaterangeIncomes(state, incomes) {
            incomes.forEach(({ daterange, restaurantsKey, income }) => Vue.set(state.data, `${daterangeKey(daterange)}-${restaurantsKey}`, income));
        },
        resetIncomeState(state) {
            Object.assign(state, incomeInitialState());
        }
    },
    getters: {
        dynamicDaterangeLength(state) {
            if (isDaterangeSet(state.dynamicDaterange)) {
                return daterangeDaysCount(state.dynamicDaterange);
            }
        },
    },
    actions: {
        requestIncomes: ({ commit, rootState }, { dateranges, restaurants, restaurantsKey }) => Promise.all(
            dateranges.map(
                daterange => axios.get("/report/income", {
                    params: {
                        key: rootState.auth.user.key,
                        date_start: dateKey(daterange.start),
                        date_end: dateKey(daterange.end),
                        restaurants: restaurants?.map(({ title }) => title),
                    }
                })
            )
        ).then(
            responses => responses.map(
                ({ data: income }, index) => ({ daterange: dateranges[index], restaurantsKey, income })
            )
        ).then(incomes => commit("setDaterangeIncomes", incomes))
    }
};
