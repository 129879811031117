import Vue from "vue";
import axios from "axios";
import router from "@/router";

const ID_RIGHT_ADMIN = 1;

function authInitialState() {
    let storageUser = localStorage.getItem("user");

    if (storageUser) {
        return {
            user: JSON.parse(storageUser)
        };
    }

    return {
        user: {}
    };
}

export default {
    state: () => authInitialState(),
    getters: {
        user: state => state.user,
        userMethods: state => state.user.methods,
        userRestaurants: state => state.user.restaurants,
        userRestaurantsNormalized: state => state.user.restaurants,
        userRestaurantsList: state => state.user?.restaurants?.ids?.map(id => state.user?.restaurants?.byId?.[id]),
        userAttestationDepartments: state => state.user.attestation_departments,
        userStaffDepartments: state => state.user.staff_departments,
        userUnitOrigins: state => state.user.unit_origins,
        isAuthenticated: state => Boolean(state.user.key),
        isAdmin: state => state.user.id_right === ID_RIGHT_ADMIN,
    },
    mutations: {
        setUser(state, user) {
            Vue.set(state, "user", user);
            localStorage.setItem("user", JSON.stringify(user));
        },
        clearUser(state) {
            localStorage.removeItem("user");
            Object.assign(state, authInitialState());
        }
    },
    actions: {
        login: ({ commit }, { username: login, password }) => axios.get("/auth", { params: { login, password } }).then(
            ({ data: { key, user } }) => commit(
                "setUser",
                {
                    key,
                    ...user,
                    restaurants: {
                        ids: user.restaurants.engs,
                        byId: {
                            ...Object.fromEntries(
                                Object.entries(user.restaurants.byEng).map(
                                    ([eng, restaurant]) => ([eng, { id: eng, title: restaurant.name, titleExcel: restaurant.excel }])
                                )
                            )
                        }
                    }
                }
            )
        ),
        logout: ({ commit }) => {
            commit("resetIncomeState");
            commit("resetLoadState");
            commit("resetEfficiencyState");
            commit("resetUnitState");
            commit("resetUnitState2");
            commit("resetTimingState");
            commit("resetTimingHallState");
            commit("resetCookingState");
            commit("resetTripState");
            commit("resetToneState");
            commit("resetAttestationState");
            commit("resetStaffState");
            commit("resetPageState");
            commit("resetDangerState");
            commit("resetDishState");
            commit("resetUsageState");
            commit("resetReportState");
            commit("resetSettingsState");
            commit("resetDirectoriesState");
            commit("clearUser");
            router.push("/login");
        }
    }
};
