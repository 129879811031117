<template>
    <transition-group class="messages" tag="div" name="slide">
        <p class="messages__item"
            :class="`messages__item_${message.type}`"
            v-for="message in messages"
            :key="message.id"
        >
            <span class="messages__item-text">{{message.text}}</span>
            <button class="messages__item-close" @click="removeMessage(message.id)">
                <svg-icon class="messages__item-icon" icon="close" />
            </button>
        </p>
    </transition-group>
</template>

<script>
    import { mapState, mapMutations } from "vuex";

    export default{
        name: "Messages",
        computed: {
            ...mapState({
                messages: state => state.ui.messages
            }),
        },
        methods: {
            ...mapMutations([
                "removeMessage"
            ])
        }
    }
</script>

<style lang="scss">
    .messages {
        display: flex;
        flex-flow: column-reverse nowrap;
        justify-content: flex-start;
        align-items: flex-end;
    }
    .messages__item {
        min-width: 300px;
        max-width: 500px;
        padding: 20px 40px 20px 20px;
        border: 2px solid;
        border-radius: 6px;
        background-color: rgba(#fff, 0.925);
        font-size: 18px;
        font-weight: bold;
        position: relative;

        transition-property: transfrom, opacity;
        transition-duration: 500ms;

        & + & {
            margin-bottom: 10px;
        }

        &_success {
            color: $green-primary;
        }
        &_failure {
            color: $red-primary;
        }
    }
    .messages__item-close{
        width: 16px;
        height: 16px;
        fill: currentColor;

        position: absolute;
        top: 10px;
        right: 10px;
    }
    .messages__item-icon {
        width: 100%;
        height: 100%;

        .messages__item_success & {
            fill: $green-primary;
        }

        .messages__item_failure & {
            fill: $red-primary;
        }
    }
    .slide-enter-active {
        animation: slide 200ms normal ease-out forwards;
    }
    .slide-leave-active {
        animation: slide 200ms reverse ease-out forwards;
    }

    @keyframes slide {
        from {
            transform: translateY(20px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
        }
    }
</style>
