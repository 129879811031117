import formatDate from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import isFirstDayOfMonth from "date-fns/isFirstDayOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfYesterday from "date-fns/startOfYesterday";
import endOfYesterday from "date-fns/endOfYesterday";
import endOfToday from "date-fns/endOfToday";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import parseISO from "date-fns/parseISO";
import ru from "date-fns/locale/ru";

export function daterangeFromStartOfMonthToYesrerday() {
    let today = new Date();

    if (isFirstDayOfMonth(today)) {
        let yesterday = startOfYesterday();

        return {
            start: startOfMonth(yesterday),
            end: endOfMonth(yesterday)
        }
    }

    return {
        start: startOfMonth(today),
        end: endOfYesterday()
    };
}

export function daterangeFromStartOfMonthToToday() {
    return {
        start: startOfMonth(new Date()),
        end: endOfToday()
    };
}

export function isDaterangeSet({ start, end }) {
    return start instanceof Date && end instanceof Date;
}

export function daterangeKey({ start, end }) {
    return `${formatDate(start, "yyyy.MM.dd")}-${formatDate(end, "yyyy.MM.dd")}`;
}

export function daterangeDaysCount(daterange) {
    return eachDayOfInterval(daterange).length;
}

export function daterangeText(daterange) {
    if (isDaterangeSet(daterange)) {
        let { start, end } = daterange;

        if (isSameDay(start, end)) {
            return dateTextLong(start);
        }

        return `${formatDate(start, "dd.MM.yyyy")} – ${formatDate(end, "dd.MM.yyyy")}`;
    }

    return "не выбран";
}

export function dateKey(date) {
    return formatDate(date, "yyyy-MM-dd");
}

export function dateText(date) {
    return date instanceof Date ? formatDate(date, "dd.MM.yyyy") : "";
}

export function dateTextLong(date) {
    return date instanceof Date ? formatDate(date, "d MMMM yyyy г.", { locale: ru }) : "";
}

export function datetimeText(date) {
    return date instanceof Date ? formatDate(date, "dd.MM.yyyy г. hh:mm:ss", { locale: ru }) : "";
}

export function dateMonthYearText(date) {
    return date instanceof Date ? formatDate(date, "LLLL yyyy г.", { locale: ru }) : "";
}

export function minDaterangeDate() {
    return parseISO("2021-01-01");
}
