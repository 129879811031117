import { daterangeFromStartOfMonthToYesrerday, daterangeDaysCount, daterangeKey } from "@/helpers/daterange";
import { restaurantsKey } from "@/helpers/restaurants";

function reportInitialState() {
    return {
        daterange: daterangeFromStartOfMonthToYesrerday(),
        restaurants: []
    };
}

export default {
    state: () => reportInitialState(),
    mutations: {
        setReportDaterange(state, daterange) {
            state.daterange = daterange;
        },
        setReportParameter(state, { parameter, value }) {
            state[parameter] = value;
        },
        resetReportState(state) {
            Object.assign(state, reportInitialState());
        }
    },
    getters: {
        reportDaterangeLength(state) {
            return daterangeDaysCount(state.daterange);
        },
        reportDaterangeKey(state) {
            return daterangeKey(state.daterange);
        },
        reportRestaurantsKey(state) {
            return restaurantsKey(state.restaurants);
        },
        reportKey(state, { reportDaterangeKey, reportRestaurantsKey }) {
            return `${reportDaterangeKey}-${reportRestaurantsKey}`;
        },
    }
};
