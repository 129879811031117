import Vue from "vue";
import axios from "axios";

function directoriesInitialState() {
    return {
        users: {
            ids: [],
            byId: {},
        },
        restaurants: {
            engs: [],
            byEng: {},
        },
        wageGroups: {
            ids: [],
            byId: {},
        },
        methods: {},
        rights: [],
        serviceTypes: [],
    };
}

export default {
    state: () => directoriesInitialState(),
    getters: {
        areDirectoriesLoaded: state => state.users.ids.length > 0,
    },
    mutations: {
        resetDirectoriesState(state) {
            Object.assign(state, directoriesInitialState());
        },
        setUsersInState(state, users) {
            Vue.set(state.users, "ids", users.ids);
            Vue.set(state.users, "byId", users.byId);
        },
        setRestaurantsInState(state, restaurants) {
            Vue.set(state.restaurants, "engs", restaurants.engs);
            Vue.set(state.restaurants, "byEng", restaurants.byEng);
        },
        setWageGroupsInState(state, wageGroups) {
            Vue.set(state.wageGroups, "ids", wageGroups.ids);
            Vue.set(state.wageGroups, "byId", wageGroups.byId);
        },
        setRightsInState(state, rights) {
            Vue.set(state, "rights", rights);
        },
        setMethodsInState(state, methods) {
            Vue.set(state, "methods", methods);
        },
        setServiceTypesInState(state, serviceTypes) {
            Vue.set(state, "serviceTypes", serviceTypes);
        },
        deleteUserFromState(state, userToDeleteId) {
            state.users.ids = state.users.ids.filter(id => id !== userToDeleteId);
            delete state.users.byId[userToDeleteId];
        },
        updateUserInState(state, user) {
            state.users.byId[user.id] = {
                ...user
            };
        },
        addUserToState(state, user) {
            state.users.ids.push(user.id);
            state.users.byId[user.id] = user;
        }
    },
    actions: {
        getDirectoriesFromDB: ({ commit, rootState }) => axios.get("/get/directories", {
            params: { key: rootState.auth.user.key }
        }).then(
            ({ data: { users, restaurants, rights, methods, wage_groups, service_types } }) => {
                let intop =  Object.values(users.byId).find(user => user.login === "intop");

                if (intop) {
                    users.ids = users.ids.filter(id => id !== intop.id);
                    delete users.byId[intop.id];
                }

                commit("setUsersInState", users);
                commit("setRestaurantsInState", restaurants);
                commit("setRightsInState", rights);
                commit("setMethodsInState", methods);
                commit("setWageGroupsInState", wage_groups);
                commit("setServiceTypesInState", service_types);
            }
        ),
        deleteUserFromDB: ({ commit, rootState }, id) => axios.get("/set/user/delete", {
            params: {
                key: rootState.auth.user.key,
                id
            }
        }).then(() => commit("deleteUserFromState", id)),
        updateUserInDB: ({ commit, rootState }, { id, updates }) => axios.get("/set/user/update", {
            params: {
                key: rootState.auth.user.key,
                id,
                ...updates
            }
        }).then(({ data: { user }}) => {
            commit("updateUserInState", user);
            return user;
        }),
        addUserToDB: ({ commit, rootState }, userData) => axios.get("/set/user/add", {
            params: {
                key: rootState.auth.user.key,
                ...userData
            }
        }).then(({ data: { user }}) => {
            commit("addUserToState", user);
            return user;
        }),
    }
};
