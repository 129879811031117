<template>
    <nav class="navbar">
        <header class="navbar__header">
            <router-link class="navbar__home-link" to="/" title="Главная страница" exact>
                <img class="navbar__logo" src="/img/profranshiza-logo.png" />
            </router-link>
            <button class="navbar__toggler" @click="toggleNavbar(!isNavbarOpened)">
                <svg-icon class="navbar__toggler-icon" icon="menu"/>
            </button>
        </header>
        <div class="navbar__links">
            <router-link v-for="navLink in navLinks" :key="navLink.path"
                :class="`navbar__link navbar__link_${navLink.path.substr(1)} link`"
                active-class="navbar__link_active"
                :to="navLink.path"
                :title="navLink.title"
            >
                <svg-icon class="navbar__link-icon" :icon="navLink.icon"/>
                <span class="navbar__link-title">{{navLink.title}}</span>
            </router-link>
        </div>
    </nav>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";

export default {
    name: "Navbar",
    computed: {
        ...mapState({
            isNavbarOpened: state => state.ui.isNavbarOpened,
        }),
        ...mapGetters([
            "isAdmin",
            "userMethods"
        ]),
        navLinks() {
            return this.$router.options.routes.filter(
                ({ isInNavbar }) => Boolean(isInNavbar)
            ).map(
                route => {
                    if (route.children) {
                        for (const childRoute of route.children) {
                            if (childRoute.meta.navbarIcon) {
                                route.meta = childRoute.meta;
                            }
                        }
                    }

                    return route;
                }
            ).filter(
                route => {
                    if (route.path === "/settings") {
                        return route.children.filter(({ path }) => Boolean(path)).some(this.isUserAllowed);
                    }

                    return this.isUserAllowed(route);
                }
            ).map(
                ({ path, meta: { title, navbarIcon: icon} }) => ({ path, title, icon })
            );
        }
    },
    methods: {
        ...mapMutations([
            "toggleNavbar"
        ]),
        isUserAllowed({ meta: { requiresAdmin, requiredMethodURLs } }) {
            if (requiresAdmin) {
                return this.isAdmin;
            } else if (requiredMethodURLs) {
                return requiredMethodURLs.every(
                    methodURL => this.userMethods?.URLs?.includes(methodURL)
                );
            }

            return true;
        }
    }
}
</script>

<style lang="scss">
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    background-color: $background-black-primary;

    @include to-desktop {
        &_opened {
            bottom: 0;
        }
    }

    @include desktop {
        bottom: 0;
        right: unset;

        width: 90px;
        padding-bottom: 15px;
        overflow-y: auto;

        &_opened  {
            width: 240px;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
.navbar__header {
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    @include desktop {
        position: sticky;
        top: 0;
        z-index: 1;

        height: auto;
        padding: 15px 10px;
        background-color: #232527;
        justify-content: center;

        .navbar_opened & {
            justify-content: space-between;
        }
    }
}
.navbar__logo {
    width: 125px;
    fill: $text-gray-secondary;

    @include desktop {
        display: none;

        .navbar_opened & {
            display: block;
        }
    }
}
.navbar__toggler {
    @include center;
    width: 32px;
    height: 32px;

    &:focus {
        outline: none;
    }
}
.navbar__toggler-icon {
    width: 24px;
    height: 24px;
    fill: #fff;

    .navbar_opened & {
        fill: $text-gray-secondary;
    }
}
.navbar__links {
    display: none;

    @include to-desktop {
        .navbar_opened & {
            display: block;
            height: calc(100% - 50px);
            padding-bottom: 10px;
            overflow-y: auto;
        }
    }

    @include desktop {
        display: block;
    }
}
.navbar__link {
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    color: $text-gray-secondary;
    font-size: 16px;
    line-height: 1.5;

    &:hover {
        color: $gray-line;
    }

    &_active {
        color: $gray-line;
        background-color: $background-black-secondary;

        position: relative;

        &::before {
            content: "";
            width: 4px;
            background-color: $text-gray-secondary;

            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
    }

    .navbar_opened & {
        padding: 0 15px 0 20px;
        justify-content: flex-start;
    }

    &_news {
        border-top: 1px solid rgba($text-gray-secondary, 0.35);
    }
}
.navbar__link-icon {
    width: 16px;
    height: 16px;
    fill: currentColor;

    .navbar_opened & {
        margin-right: 15px;
    }
}
.navbar__link-title {
    display: none;

    .navbar_opened & {
        display: block;
        white-space: nowrap;
    }
}
</style>
