import Vue from "vue";
import axios from "axios";
import { daterangeKey, dateKey } from "@/helpers/daterange";

function tripInitialState() {
    return {
        data: {},
        modeId: "base",
        compareDaterange: { start: null, end: null },
    };
}

export default {
    state: () => tripInitialState(),
    mutations: {
        setTripMode(state, id) {
            state.modeId = id;
        },
        setTripCompareDaterange(state, daterange) {
            state.compareDaterange = daterange;
        },
        setDaterangeTrips(state, trips) {
            trips.forEach(({ daterange, restaurantsKey, trip }) => Vue.set(state.data, `${daterangeKey(daterange)}-${restaurantsKey}`, trip));
        },
        resetTripState(state) {
            Object.assign(state, tripInitialState());
        }
    },
    actions: {
        requestTrips: ({ commit, rootState }, { dateranges, restaurants, restaurantsKey }) => Promise.all(
            dateranges.map(
                daterange => axios.get("/report/trip", {
                    params: {
                        key: rootState.auth.user.key,
                        date_start: dateKey(daterange.start),
                        date_end: dateKey(daterange.end),
                        restaurants: restaurants?.map(({ title }) => title),
                    }
                })
            )
        ).then(
            responses => responses.map(
                ({ data: trip }, index) => ({ daterange: dateranges[index], restaurantsKey, trip })
            )
        ).then(trips => commit("setDaterangeTrips", trips))
    }
};
