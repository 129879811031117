import Vue from "vue";
import axios from "axios";

function attestationInitialState() {
    return {
        data: {},
        filterId: "all",
        isFiltersVisible: true,
        totalSorting: {
            field: "validated_percent",
            direction: "DESC"
        },
        sortings: {
            total: {
                field: "validated_percent",
                direction: "DESC"
            },
            department: {
                field: "percentage",
                direction: "DESC"
            }
        }
    };
}

export default {
    state: () => attestationInitialState(),
    mutations: {
        setAttestationFilter(state, id) {
            state.filterId = id;
        },
        toggleAttestationFilters(state, isFiltersVisible) {
            state.isFiltersVisible = isFiltersVisible;
        },
        setAttestationSortingField(state, { table, field }) {
            state.sortings[table].field = field;
        },
        setAttestationSortingDirection(state, { table, direction }) {
            state.sortings[table].direction = direction;
        },
        setAttestationData(state, data) {
            Vue.set(state, "data", data)
        },
        resetAttestationState(state) {
            Object.assign(state, attestationInitialState());
        }
    },
    actions: {
        requestAttestation: ({ commit, rootState }) => axios.get("/report/validation", {
            params: { key: rootState.auth.user.key }
        }).then(({ data }) => commit("setAttestationData", data))
    }
};
