<template>
    <div class="dashboard">
        <template v-if="isAuthenticated">
            <navbar :class="navbarClass" />
            <page :class="pageClass" />
        </template>
        <router-view v-else class="dashboard__login" />
        <svg-icons class="dashboard__icons" />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import Navbar from "@/components/Navbar";
    import Page from "@/components/Page";
    import SvgIcons from "@/assets/img/svg-icons.svg";

    export default {
        name: "Dashboard",
        components: {
            Navbar,
            Page,
            SvgIcons
        },
        computed: {
            ...mapState({
                isNavbarOpened: state => state.ui.isNavbarOpened
            }),
            ...mapGetters([
                "isAuthenticated"
            ]),
            navbarClass() {
                return [
                    "dashboard__navbar",
                    {
                        "navbar_opened": this.isNavbarOpened
                    }
                ];
            },
            pageClass() {
                return [
                    "dashboard__page",
                    {
                        "dashboard__page_narrow": this.isNavbarOpened
                    }
                ];
            }
        }
    }
</script>

<style lang="scss">
    .dashboard__page {
        margin-top: 50px;

        @include desktop {
            margin: 0 0 0 90px;

            &_narrow  {
                margin-left: 240px;
            }
        }
    }
    .dashboard__icons {
        display: none;
    }
</style>
